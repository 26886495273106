<template>
    <v-dialog v-model="visible" width="400">
      <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="indigo lighten-1"
        depressed
        icon
        small
      >
        <v-icon small>mdi-lock</v-icon>
      </v-btn>
      </template>
      <v-card>
        <v-card-title>
        <v-row justify="space-between">
          <v-col>
            {{ fullName }}
          </v-col>
          <v-col cols="auto" v-if="primaryContact">
            <v-chip color="green" text-color="white" small>
              <v-avatar left>
                <v-icon small>mdi-account-circle</v-icon>
              </v-avatar>
              Primary Contact
            </v-chip>
          </v-col>
        </v-row>
        </v-card-title>
        <v-card-subtitle class="pt-1">
         Security Password
        </v-card-subtitle>

        <div class="pb-5 px-5">
            <v-row >
              <v-col >
                <DataValue
                  label="Password"
                  :value="encryptedPassword"
                  :icon="passwordVisible ? 'eye' : 'eye-off'"
                  icon-color="deep-purple lighten-2 cursor-pointer"
                  @icon-click="togglePasswordVisibility"
                />
              </v-col>
            </v-row>
        </div>
        <v-card-actions>
          <v-btn text @click="visible = false">
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue" text @click="openFormAndClose">
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DataValue from "@/components/data-value"
export default {
    components: {
        DataValue,
      },
  data: () => ({ visible: false, passwordVisible: false}),
  props: {
    fullName: {
    type: String,
    default: ""
    },
    primaryContact: {
    type: Boolean,
    default: false
    },
    contactId: {
      type: String,
      default: ""
    },
    password: {
      type: String,
      default: ""
    },
  },
  computed: {
    ...mapGetters("contacts", ["contactAddresses"]),
    encryptedPassword() {
      const pwd = this.password ? "**********" : "" // NASA encryption
      return this.passwordVisible ? this.password : pwd
    }
  },
  created() {
    this.selectContact(this.contactId)
  },
  methods: {
      ...mapMutations("contacts", {
      selectContact: "setSelectedContact"
    }),
    ...mapActions("ui", ["openForm"]),
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible
    },
    openFormAndClose() {
      this.visible = false
      this.selectContact(this.contactId)
      this.openForm({ formName: "security-password", edit: true })
    },
  }
}
</script>